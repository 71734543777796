<template>
  <div>
    <div class="page-title">
      <h3>
        Редактирование пользователя
        <button class="btn waves-effect waves-light btn-small" @click="refreshTracking" title="Перезапустить трекинг">
          <i class="material-icons">refresh</i>
        </button>
      </h3>
    </div>

    <Loader v-if="loading"/>

    <form class="form" @submit.prevent="submitHandler">
      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="last_name" type="text" v-model="last_name"
                   :class="{invalid: $v.last_name.$error}">
            <label for="last_name">Фамилия</label>
            <small class="helper-text invalid" v-if="$v.last_name.$error">Введите фамилию</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="id" type="text" v-model="profile.id" disabled>
            <label for="id">ID</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="first_name" type="text" v-model="first_name"
                   :class="{invalid: $v.first_name.$error}">
            <label for="first_name">Имя</label>
            <small class="helper-text invalid" v-if="$v.first_name.$error">Введите имя</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <select id="role" ref="roleSelect" v-model="role">
              <option v-for="r in roles" :key="r.id" :value="r.id">{{ r.name }}</option>
            </select>
            <label for="role">Роль</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="middle_name" type="text" v-model="middle_name"
                   :class="{invalid: $v.middle_name.$error}">
            <label for="middle_name">Отчество</label>
            <small class="helper-text invalid" v-if="$v.middle_name.$error">Введите отчество</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="email" type="text" v-model="email"
                   :class="{invalid: $v.email.$dirty && !$v.email.email}">
            <label for="email">Электронная почта</label>
            <small class="helper-text invalid" v-if="$v.email.$error">Введите
              адрес электронной почты</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="position" type="text" v-model="position">
            <label for="position">Должность</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="telegram_id" type="text" v-model="telegram_id"
                   :class="{invalid: $v.telegram_id.$dirty && !$v.telegram_id.numeric}">
            <label for="telegram_id">Телеграм ID</label>
            <small class="helper-text invalid" v-if="$v.telegram_id.$error">
              Введите идентификатор телеграма
            </small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <select id="department" ref="departmentSelect" v-model="department">
              <option value="" disabled selected>Выбрать</option>
              <option v-for="r in departments" :key="r.id" :value="r.id">{{ r.name }}</option>
            </select>
            <label for="department">Отдел</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="phone_work" type="text" v-model="phone_home">
            <label for="phone_work">Рабочий телефон</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="team" type="text" v-model="team_name" disabled>
            <label for="team">Бригада</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="device_id" type="text" v-model="device_id">
            <label for="device_id">ID привязанного телефона</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <select id="home_location" ref="homeLocationSelect" v-model="home_location">
              <option value="" selected>Без локации</option>
              <option v-for="r in home_locations" :key="r.id" :value="r.id">{{ r.name }}</option>
            </select>
            <label for="home_location">Домашняя локация</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="app_version" type="text" v-model="app_version" disabled>
            <label for="app_version">Версия приложения </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="created_at" type="text" v-model="created_at" disabled>
            <label for="created_at">Время создания</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="phone_home" type="text" v-model="phone_home">
            <label for="phone_home">Домашний телефон</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="updated_at" type="text" v-model="updated_at" disabled>
            <label for="updated_at">Дата изменения</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="car_number" type="text" v-model="car_number">
            <label for="car_number">Номер автомобиля</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">

          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="car_model" type="text" v-model="car_model">
            <label for="car_model">Марка автомобиля</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s5">
          <button class="btn waves-effect waves-light blue darken-4" type="submit">
            Сохранить
            <i class="material-icons right">send</i>
          </button>
        </div>
        <div class="col s4" v-if="!is_active">
          <a class="btn waves-effect waves-light light-green darken-4" @click="activateUser">Активировать</a>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <a class="btn waves-effect waves-light red darken-4" @click="resetPassword">Сбросить пароль</a>
        </div>
        <div class="col s3">
          <a class="btn waves-effect waves-light red darken-4" @click="deleteUser">Удалить</a>
        </div>
      </div>
    </form>

    <div id="modalDeactivateUser" class="modal">
      <div class="modal-content">
        <h4>Деактивация аккаунта</h4>
        <p>Вы действительно хотите деактивировать акканут?</p>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect blue darken-4 btn-flat white-text">Нет</a>
        &nbsp;&nbsp;&nbsp;
        <a class="waves-effect red darken-4 btn-flat white-text"
           v-on:click="deleteSubmit">Да</a>
      </div>
    </div>

    <div id="modalResetPassword" class="modal">
      <div class="modal-content">
        <h4>Сброс пароля</h4>
        <p>Вы действительно хотите обновить пароль пользователю?</p>
        <p>Новый пароль будет сгенерирован автоматически и отправлен пользователю сообщением в Telegram.</p>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect blue darken-4 btn-flat white-text">Нет</a>
        &nbsp;&nbsp;&nbsp;
        <a class="waves-effect red darken-4 btn-flat white-text"
           v-on:click="resetPasswordSubmit">Да</a>
      </div>
    </div>

    <!--<div id="modalChangeDepartment" class="modal">
      <div class="modal-content">
        <h4>Изменение отдела</h4>
        <p>
          При изменении отдела пользователь будет удален из существующей бригады.<br/>
          Вы действительно хотите изменить отдел?
        </p>
      </div>
      <div class="modal-footer">
        <a class="waves-effect blue darken-4 btn-flat white-text"
           v-on:click="cancelChangeDepartment">Нет</a>
        &nbsp;&nbsp;&nbsp;
        <a class="waves-effect red darken-4 btn-flat white-text"
           v-on:click="submitChangeDepartment">Да</a>
      </div>
    </div>-->
  </div>
</template>

<script>
import {required, email, alpha, numeric} from 'vuelidate/lib/validators'
import dateFilter from '../../filters/date.filter'

export default {
  metaInfo() {
    return {
      title: 'Редактирование пользователя'
    }
  },
  data: () => ({
    loading: true,
    modalDeactivateUser: null,
    modalResetPassword: null,
    modalChangeDepartment: null,
    roleSelect: null,
    departmentSelect: null,
    homeLocationSelect: null,
    profile: {},
    roles: [],
    departments: [],
    home_locations: [],
    role: '',
    team_name: '',
    department: 0,
    tempDepartment: 0,
    home_location: 0,
    position: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    telegram_id: '',
    phone_home: '',
    phone_work: '',
    car_model: '',
    car_number: '',
    device_id: '',
    app_version: '',
    created_at: '',
    updated_at: '',
    is_active: false,
  }),
  validations: {
    first_name: {required},
    last_name: {required},
    middle_name: {required},
    email: {required, email},
    telegram_id: {numeric},
  },
  async mounted() {
    this.profile = await this.$store.dispatch('fetchUserById', this.$route.params.id)

    this.first_name = this.profile.first_name || ''
    this.last_name = this.profile.last_name || ''
    this.middle_name = this.profile.middle_name || ''
    this.email = this.profile.email || ''
    this.position = this.profile.position || ''
    this.role = this.profile.role || ''
    this.department = this.profile.department ? this.profile.department.id : 0
    this.home_location = this.profile.home_location ? this.profile.home_location.id : ''
    this.team_name = this.profile.team ? this.profile.team.name : 'Нет'
    this.telegram_id = this.profile.telegram_id || ''
    this.phone_home = this.profile.phone_home || ''
    this.phone_work = this.profile.phone_work || ''
    this.car_model = this.profile.car_model || ''
    this.car_number = this.profile.car_number || ''
    this.device_id = this.profile.device_id || ''
    this.app_version = this.profile.app_version || ''
    this.is_active = this.profile.is_active || false
    this.created_at = dateFilter(this.profile.created_at.seconds * 1000, 'datetime')
    this.updated_at = dateFilter(this.profile.updated_at.seconds * 1000, 'datetime')

    this.roles = await this.$store.dispatch('getRoles')
    this.departments = await this.$store.dispatch('fetchDepartments')
    this.home_locations = await this.$store.dispatch('fetchHomeLocations')

    this.modalDeactivateUser = M.Modal.init(document.querySelector('#modalDeactivateUser'));
    this.modalResetPassword = M.Modal.init(document.querySelector('#modalResetPassword'));
    this.modalChangeDepartment = M.Modal.init(document.querySelector('#modalChangeDepartment'));

    this.loading = false

    setTimeout(() => {
      this.roleSelect = M.FormSelect.init(this.$refs.roleSelect)
      this.departmentSelect = M.FormSelect.init(this.$refs.departmentSelect)
      this.homeLocationSelect = M.FormSelect.init(this.$refs.homeLocationSelect)
      M.updateTextFields()
    })
  },
  methods: {
    isChangeDepartment() {
      if (this.profile.department != null && this.department !== this.profile.department.id) {
        this.tempDepartment = this.department
        this.department = this.profile.department.id
        this.modalChangeDepartment.open();
        this.departmentSelect.destroy()

        setTimeout(() => {
          this.departmentSelect = M.FormSelect.init(this.$refs.departmentSelect)
        })
      }
    },
    async submitChangeDepartment() {
      this.profile.department.id = this.tempDepartment
      this.department = this.tempDepartment
      this.modalChangeDepartment.close();
      this.departmentSelect.destroy()

      setTimeout(() => {
        this.departmentSelect = M.FormSelect.init(this.$refs.departmentSelect)
      })
    },
    cancelChangeDepartment() {
      this.modalChangeDepartment.close();
    },
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const user = {
        "user": {
          id: this.profile.id,
          first_name: this.first_name,
          last_name: this.last_name,
          middle_name: this.middle_name,
          email: this.email,
          position: this.position,
          role: this.role,
          department: this.department ? {"id": this.department} : null,
          home_location: this.home_location ? {"id": this.home_location} : null,
          telegram_id: Number(this.telegram_id) || null,
          phone_home: this.phone_home || 0,
          phone_work: this.phone_work || 0,
          car_model: this.car_model,
          car_number: this.car_number,
          device_id: this.device_id,
          is_active: true,
        }
      }

      await this.$store.dispatch('updateUser', user)

      if (user.user.id === await this.$store.dispatch('profile/getUid')) {
        this.$store.commit('profile/user', {
          id: user.user.id,
          email: user.user.email,
          role: user.user.role,
          name: user.user.first_name + ' ' + user.user.last_name,
          department: user.user.department,
        });
      }

      await this.$router.push('/users')
    },
    deleteUser() {
      this.modalDeactivateUser.open();
    },
    async deleteSubmit() {
      await this.$store.dispatch('deleteUser', this.profile.id)
      await this.$router.push('/users')
    },
    async activateUser() {
      await this.$store.dispatch('activateUser', this.profile.id)
      this.is_active = true
    },
    async resetPassword() {
      this.modalResetPassword.open();
    },
    async resetPasswordSubmit() {
      await this.$store.dispatch('resetPassword', this.profile.id)
      this.modalResetPassword.close();
    },
    refreshTracking() {
      this.$store.dispatch('refreshTracking', this.profile.id)
    },
  },
  destroyed() {
    if (this.roleSelect && this.roleSelect.destroy) {
      this.roleSelect.destroy()
    }
    if (this.homeLocationSelect && this.homeLocationSelect.destroy) {
      this.homeLocationSelect.destroy()
    }
    if (this.departmentSelect && this.departmentSelect.destroy) {
      this.departmentSelect.destroy()
    }
    this.modalDeactivateUser.destroy();
    this.modalResetPassword.destroy();
    this.modalChangeDepartment.destroy();
  }
}
</script>

<style scoped>
.switch {
  margin-top: 1rem;
}
</style>
